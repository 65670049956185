<template>
  <span>{{ telefone }}</span>
</template>

<script>
import { computed } from "@vue/reactivity";

export default {
  name: "Fone",
  props: {
    valor: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const telefone = computed(function () {
      const fone = props.valor.trim().replace(/^\+55/g, "");
      const quebra = fone.length > 10 ? 7 : 6;
      return `(${fone.substring(
        0,
        2
      )}) ${fone.substring(2, quebra)}-${fone.substring(quebra)}`;
    });

    return { telefone };
  },
};
</script>