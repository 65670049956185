<template>
  <button class="btn botao-base-pagina shadow-sm" @click="aoClicar">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BotaoBasePagina",
  props: {
    aoClicar: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.botao-base-pagina {
  color: var(--cor-tema-fundo);
  background-color: var(--cor-tema-escura);
  display: block;
  width: 100%;
  margin: 16px auto;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 1.2em;
}
</style>
