<template>
  <div class="filtro-mercadorias-nome d-flex flex-row">
    <input
      type="text"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />

    <button>
      <IconeLupa class="icone" />
    </button>
  </div>
</template>

<script>
import IconeLupa from "@/components/shared/imagens/IconeLupa.vue";

export default {
  name: "FiltroMercadoriasPorNome",
  components: {
    IconeLupa,
  },
  props: {
    modelValue: String,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.filtro-mercadorias-nome {
  border-style: solid;
  border-color: var(--cor-tema);
  border-width: 2px;
  border-radius: 20px;
  background-color: var(--cor-tema-fundo);
}
input {
  width: 100%;
  margin: 4px 0px 4px 12px;
  border: none;
}
input:focus {
  outline: none;
}
button {
  border: none;
  background: transparent;
}
button .icone {
  width: 60%;
  height: 60%;
}
</style>
