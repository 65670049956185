<template>
  <div>
    <CatalogoLoja :loja="loja" v-if="loja.identificador" />
    <Rodape />
  </div>
</template>

<script>
import CatalogoLoja from "@/components/loja/CatalogoLoja.vue";
import Rodape from "@/components/shared/Rodape.vue";

import { computed } from "@vue/reactivity";
import { SALVA_LOJA } from "@/store/mutacoes";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useHead } from "@vueuse/head";
import { useApi } from "@/composables/useApi";

export default {
  name: "Loja",
  props: {
    id: { type: String, required: true },
  },
  components: {
    CatalogoLoja,
    Rodape,
  },
  setup() {
    const store = useStore();
    const loja = computed(() => store.state.loja);

    const route = useRoute();
    useHead({
      title: computed(() => `${loja.value.nome} | Loja MEI Grátis`),
      meta: [
        {
          name: "og:url",
          content: `${process.env.VUE_APP_BASE_URL}${route.path}`,
        },
        {
          name: "og:title",
          content: computed(() => loja.value.nome),
        },
        {
          name: "og:description",
          content: computed(() => loja.value.descricao ?? ""),
        },
        {
          name: "og:image",
          content: computed(() => loja.value.logotipo ?? ""),
        },
        {
          name: "og:type",
          content: "website",
        },
        {
          name: "og:locale",
          content: "pt_BR",
        },
      ],
      link: [
        {
          id: "favicon",
          rel: "icon",
          href: computed(() => `${loja.value.logotipo ?? "/favicon.png"}`),
        },
        {
          rel: "stylesheet",
          href: computed(() => `/temas/${loja.value.tema}.css`),
        },
      ],
      script: [
        {
          type: "application/ld+json",
          children: computed(
            () => `{
              "@context": "https://schema.org",
              "@type": "Store",
              "name": "${loja.value.nome}",
              "image": [
                "${loja.value.logotipo ?? ""}"
              ],
              "url": "${loja.value.site ?? ""}",
              "telephone": "${loja.value.telefone ?? ""}"
            }`
          ),
        },
      ],
    });

    return {
      loja,
    };
  },
  async beforeRouteEnter(origem, destino, proximo) {
    const { buscaLoja } = useApi();
    const loja = await buscaLoja(origem.params.id);
    if (loja && loja.mercadorias) {
      proximo((vm) => {
        vm.$store.commit(SALVA_LOJA, loja);
      });
    } else {
      proximo({ name: "loja-desconhecida" });
    }
  },
};
</script>