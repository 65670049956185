<template>
  <div class="mb-3">
    <div class="cabecalho d-flex flex-column align-items-center">
      <div
        class="avatar flex-shrink-0 my-3"
        :class="{ 'sem-imagem': !loja.logotipo }"
      >
        <img v-if="loja.logotipo" :src="loja.logotipo" />
        <span v-else>{{ loja.nome[0] }}</span>
      </div>
      <div class="flex-grow-1 mb-2">
        <h1 class="text-center fw-bold m-0">{{ loja.nome }}</h1>
      </div>
    </div>

    <div class="d-flex flex-column align-items-center px-3 py-4">
      <div class="descricao small text-center mb-3" v-if="loja.descricao">
        {{ loja.descricao }}
      </div>
      <div class="endereco small text-center mb-3" v-if="loja.endereco">
        {{ loja.endereco }}
      </div>
      <div class="contatos">
        <div role="button" v-if="loja.celular">
          <a
            :href="`https://wa.me/${numeroWhatsapp}?text=${mensagemWhatsapp}`"
            target="_blank"
          >
            <IconeWhatsapp class="icone" />
          </a>
        </div>
        <div
          role="button"
          class="icone"
          v-if="loja.telefone"
          @click="mostrarTelefone"
        >
          <IconeTelefone class="icone" />
        </div>
        <div role="button" v-if="loja.site">
          <a :href="loja.site" target="_blank">
            <IconeSite class="icone" />
          </a>
        </div>
      </div>
    </div>

    <ModalMensagem
      identificador="modal-mostrar-telefone"
      :conteudo-centralizado="true"
    >
      <template v-slot:cabecalho>Telefone</template>
      <template v-slot:corpo>
        <Fone :valor="loja.telefone" v-if="loja.telefone" />
      </template>
    </ModalMensagem>
  </div>
</template>

<script>
import IconeSite from "@/components/shared/imagens/IconeSite.vue";
import IconeTelefone from "@/components/shared/imagens/IconeTelefone.vue";
import IconeWhatsapp from "@/components/shared/imagens/IconeWhatsapp.vue";
import ModalMensagem from "@/components/shared/modais/ModalMensagem.vue";
import Fone from "@/components/shared/formatadores/Fone.vue";
import { Modal } from "bootstrap";

export default {
  name: "CabecalhoCatalogoLoja",
  components: {
    IconeSite,
    IconeTelefone,
    IconeWhatsapp,
    ModalMensagem,
    Fone,
  },
  props: {
    param: String,
  },
  data() {
    return {};
  },
  methods: {
    mostrarTelefone() {
      const modal = new Modal(
        document.getElementById("modal-mostrar-telefone")
      );
      modal.show();
    },
  },
  computed: {
    mensagemWhatsapp() {
      const mensagem = encodeURI(
        "Olá! Acessei sua loja " +
          this.loja.nome +
          " pelo MEI Grátis e gostaria de entrar em contato para mais informações."
      );
      return mensagem;
    },
    numeroWhatsapp() {
      return this.loja.celular.replace(/\D/g, "");
    },
  },
  inject: ["loja"],
};
</script>

<style scoped>
.cabecalho {
  background-color: var(--cor-tema-clara);
  color: var(--cor-tema-fundo);
  padding: 1rem 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.cabecalho h1 {
  font-size: 1.5rem;
  font-weight: 600;
}
.avatar {
  display: flex;
  height: 90px;
  width: 90px;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: 600;
  overflow: hidden;
  border-radius: 50%;
  border-style: solid;
  border-color: var(--cor-tema-fundo);
}
.avatar img {
  max-height: 100%;
  max-width: 100%;
}
.avatar.sem-imagem {
  background-color: var(--cor-tema-clara);
}
.contatos div {
  display: inline-block;
  margin-right: 16px;
  cursor: pointer;
}
.contatos .icone {
  width: 32px;
  height: 32px;
}
</style>
