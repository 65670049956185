<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="32px"
    height="32px"
    viewBox="0 0 60.006 60.006"
    style="enable-background: new 0 0 60.006 60.006"
    xml:space="preserve"
  >
    <g>
      <path
        d="M59.427,40.907c-2.008-3.796-8.98-7.912-9.287-8.092c-0.896-0.51-1.831-0.78-2.706-0.78c-1.301,0-2.366,0.596-3.011,1.68
    c-1.02,1.22-2.285,2.646-2.592,2.867c-2.378,1.611-4.237,1.429-6.295-0.629L24.05,24.467c-2.045-2.045-2.233-3.928-0.631-6.292
    c0.224-0.31,1.649-1.575,2.869-2.595c0.778-0.463,1.312-1.15,1.545-1.994c0.312-1.123,0.082-2.444-0.652-3.733
    c-0.173-0.296-4.289-7.269-8.085-9.276c-0.708-0.375-1.505-0.573-2.306-0.573c-1.318,0-2.558,0.514-3.49,1.445l-2.536,2.537
    C6.75,7.998,5.296,12.547,6.443,17.504c0.956,4.132,3.742,8.529,8.282,13.069L29.43,45.278c4.894,4.894,9.592,7.726,14.003,8.46
    c-1.075,2.505-3.565,4.264-6.46,4.264c-3.127,0-5.832-2.013-6.73-5.008l-0.904-3.014c-0.179-0.595-0.717-0.994-1.338-0.994
    s-1.159,0.399-1.338,0.995L25,55.522l-1.662-5.541c-0.179-0.595-0.717-0.994-1.338-0.994s-1.159,0.399-1.338,0.995L19,55.522
    l-1.662-5.541c-0.179-0.595-0.717-0.994-1.338-0.994c-0.621,0-1.159,0.399-1.338,0.995L13,55.522l-1.662-5.541
    c-0.179-0.595-0.717-0.994-1.338-0.994s-1.159,0.399-1.338,0.995L7,55.522l-1.662-5.541C5.159,49.387,4.621,48.987,4,48.987
    s-1.159,0.399-1.338,0.995l-2.62,8.733c-0.158,0.528,0.142,1.086,0.671,1.245C0.809,59.989,0.906,60.003,1,60.003
    c0.431,0,0.828-0.279,0.958-0.713L4,52.483l1.662,5.541C5.841,58.619,6.379,59.018,7,59.018s1.159-0.399,1.338-0.995L10,52.483
    l1.662,5.541c0.179,0.595,0.717,0.994,1.338,0.994s1.159-0.399,1.338-0.995L16,52.483l1.662,5.541
    c0.179,0.595,0.717,0.994,1.338,0.994s1.159-0.399,1.338-0.995L22,52.483l1.662,5.541c0.179,0.595,0.717,0.994,1.338,0.994
    s1.159-0.399,1.338-0.995L28,52.482l0.326,1.087c1.154,3.849,4.629,6.434,8.646,6.434c3.942,0,7.293-2.544,8.521-6.072
    c0.072,0.001,0.146,0.008,0.218,0.008c0.001,0,0,0,0.001,0c3.721,0,7.188-1.581,10.306-4.699l2.537-2.536
    C60.096,45.161,60.447,42.832,59.427,40.907z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconeTelefone",
};
</script>

<style scoped>
path {
  fill: var(--cor-tema);
}
</style>