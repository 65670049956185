<template>
  <div class="row align-items-center">
    <div class="col">
      <button
        class="btn botao-contador"
        @click="decrementa"
        :disabled="quantidade === 0"
      >
        -
      </button>
      <span class="texto-contador">{{ quantidade }}</span>
      <button class="btn botao-contador" @click="incrementa">+</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContadorMercadorias",
  props: {
    valorInicial: {
      type: Number,
      default: () => 0,
    },
    aoAdicionar: {
      type: Function,
      required: false,
    },
    aoRemover: {
      type: Function,
      required: false,
    },
  },
  created() {
    this.$emit("update:valorFinal", this.valorInicial);
  },
  data() {
    return {
      acumulador: 0,
    };
  },
  methods: {
    incrementa() {
      this.quantidade += 1;
      this.aoAdicionar?.call();
    },
    decrementa() {
      this.quantidade -= 1;
      this.aoRemover?.call();
    },
  },
  computed: {
    quantidade: {
      get() {
        return this.valorInicial + this.acumulador;
      },
      set(novoValor) {
        this.acumulador = novoValor - this.valorInicial;
      },
    },
  },
  watch: {
    quantidade(novoValor) {
      this.$emit("update:valorFinal", novoValor);
    },
  },
};
</script>

<style scoped>
.botao-contador {
  height: 31px;
  width: 31px;
  color: var(--cor-tema);
  font-size: 18px;
  line-height: 15px;
  text-align: center;
  border-style: solid;
  border-color: var(--cor-tema);
  border-width: 2px;
  border-radius: 50%;
  font-weight: 600;
  padding: 0px 0px 0px 1px;
}
.texto-contador {
  padding: 8px;
  color: var(--cor-tema-escura);
}
</style>
