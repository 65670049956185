<template>
  <div>
    <div
      class="modal fade"
      :id="identificador"
      tabindex="-1"
      :aria-labelledby="identificador"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <div class="cabecalho modal-header">
            <slot name="cabecalho"></slot>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div
            class="modal-body"
            :class="{ centralizado: conteudoCentralizado }"
          >
            <slot name="corpo"></slot>
          </div>
          <div class="modal-footer">
            <div class="row g-0 w-100">
              <div class="col-12">
                <button class="btn botao-primario" data-bs-dismiss="modal">
                  FECHAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalMensagem",
  components: {},
  props: {
    identificador: {
      type: String,
      required: true,
    },
    conteudoCentralizado: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.modal-header {
  border-bottom: none;
}
.modal-content {
  border: none;
}
.modal-footer {
  border-top: none;
}
.modal-body.centralizado {
  text-align: center;
}
.cabecalho {
  color: var(--cor-tema);
  font-weight: 600;
  font-size: 18px;
}
.modal-footer button {
  width: 100%;
  font-weight: 600;
}
.botao-primario {
  color: var(--cor-tema-fundo);
  background-color: var(--cor-tema-escura);
}
</style>
