<template>
  <div>
    <div
      class="
        menu-horizontal
        d-flex
        flex-row flex-sm-nowrap flex-md-wrap
        p-0
        m-0
      "
    >
      <div v-for="filtro in filtros" :key="filtro" class="item flex-column">
        <BotaoFiltroPorCategoria
          :legenda="filtro"
          :selecionado="filtro === filtroAtivo"
          @click="selecionaFiltro(filtro)"
          class="my-1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BotaoFiltroPorCategoria from "./BotaoFiltroPorCategoria.vue";

export default {
  name: "FiltroMercadoriasPorCategoria",
  components: {
    BotaoFiltroPorCategoria,
  },
  props: {},
  created() {
    this.selecionaFiltro("Todos");
  },
  data() {
    return {
      filtroAtivo: "",
    };
  },
  methods: {
    selecionaFiltro(filtro) {
      this.filtroAtivo = filtro;
      this.$emit("update:filtroSelecionado", filtro);
    },
  },
  computed: {
    filtros() {
      return [
        "Todos",
        ...this.categorias.map((categoria) =>
          `${categoria.charAt(0).toUpperCase()}${categoria.slice(1)}`.trim()
        ),
      ];
    },
  },
  inject: ["categorias"],
};
</script>

<style scoped>
.menu-horizontal {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: auto;
  white-space: nowrap;
}
.menu-horizontal::-webkit-scrollbar {
  display: none;
}
.menu-horizontal .item {
  padding-right: 8px;
}
.menu-horizontal .item:last-child {
  padding-right: 0;
}
</style>
