<template>
  <div>
    <FiltroMercadoriasPorNome v-model="filtroBusca" class="mb-3" />

    <FiltroMercadoriasPorCategoria
      v-model:filtroSelecionado="filtroCategoria"
      class="mb-3 overflow-hidden"
    />

    <div class="mb-4">
      <div class="item-vazio" v-if="!mercadoriasFiltradas.length">
        Nenhum produto encontrado.
      </div>
      <ItemListaMercadoria
        class="item-lista-mercadoria"
        v-for="mercadoria in mercadoriasFiltradas"
        :key="mercadoria.id"
        :mercadoria="mercadoria"
      ></ItemListaMercadoria>
    </div>
  </div>
</template>

<script>
import FiltroMercadoriasPorNome from "./filtros/FiltroMercadoriasPorNome";
import FiltroMercadoriasPorCategoria from "./filtros/FiltroMercadoriasPorCategoria";
import ItemListaMercadoria from "./ItemListaMercadoria.vue";

export default {
  name: "ListaSelecaoMercadorias",
  components: {
    FiltroMercadoriasPorNome,
    FiltroMercadoriasPorCategoria,
    ItemListaMercadoria,
  },
  props: {},
  data() {
    return {
      filtroBusca: "",
      filtroCategoria: "",
    };
  },
  methods: {},
  computed: {
    mercadoriasFiltradas() {
      let resultado = this.mercadorias;
      if (this.filtroBusca) {
        resultado = resultado.filter((mercadoria) =>
          mercadoria.nome.toLowerCase().includes(this.filtroBusca.toLowerCase())
        );
      }

      if (this.filtroCategoria && this.filtroCategoria != "Todos") {
        resultado = resultado.filter((mercadoria) =>
          mercadoria.categorias.some(
            (categoria) =>
              categoria.trim().toLowerCase() ===
              this.filtroCategoria.trim().toLowerCase()
          )
        );
      }
      return resultado;
    },
  },
  inject: ["mercadorias"],
};
</script>

<style scoped>
.item-vazio {
  text-align: center;
  padding: 32px 0;
  background-color: var(--cor-tema-fundo);
}
</style>
