<template>
  <button
    class="btn botao-filtro-categoria"
    :class="{ 'botao-filtro-selecionado': selecionado }"
  >
    {{ legenda }}
  </button>
</template>

<script>
export default {
  name: "BotaoFiltroPorCategoria",
  props: {
    legenda: String,
    selecionado: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.botao-filtro-categoria {
  color: var(--cor-tema);
  border-color: var(--cor-tema);
  border-width: 2px;
  font-size: 18px;
  border-radius: 25px;
}
.botao-filtro-selecionado {
  color: var(--cor-tema-fundo);
  background-color: var(--cor-tema);
}
</style>
