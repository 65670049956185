<template>
  <div>
    <CabecalhoCatalogoLoja />
    <ListaSelecaoMercadorias class="mx-2" />
    <ValorTotalPedido class="ms-3 me-3" />

    <BotaoBasePagina
      class="mt-5 mb-2 px-4 py-2"
      :aoClicar="finalizarPedido"
      :disabled="total == 0"
    >
      Finalizar pedido
    </BotaoBasePagina>
  </div>
</template>

<script>
import CabecalhoCatalogoLoja from "@/components/loja/CabecalhoCatalogoLoja.vue";
import ListaSelecaoMercadorias from "@/components/loja/ListaSelecaoMercadorias.vue";
import BotaoBasePagina from "@/components/shared/botoes/BotaoBasePagina.vue";
import ValorTotalPedido from "./ValorTotalPedido.vue";

import { mapGetters } from "vuex";

export default {
  name: "CatalogoLoja",
  components: {
    CabecalhoCatalogoLoja,
    ListaSelecaoMercadorias,
    BotaoBasePagina,
    ValorTotalPedido,
  },
  props: {
    loja: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    mercadorias() {
      return this.loja.mercadorias;
    },
    categorias() {
      return [...new Set(this.loja.mercadorias.flatMap((m) => m.categorias))];
    },
    ...mapGetters(["total"]),
  },
  methods: {
    finalizarPedido() {
      this.$router.push({
        name: "pedido-novo",
      });
    },
  },
  provide() {
    return {
      loja: this.loja,
      categorias: this.categorias,
      mercadorias: this.mercadorias,
    };
  },
};
</script>
