<template>
  <div>
    <div class="row pointer g-0 p-2">
      <div class="col">
        <router-link
          class="link-mercadoria"
          replace
          :to="{
            name: 'mercadoria',
            params: {
              idMercadoria: mercadoria.id,
              slugMercadoria: mercadoria.slug,
            },
          }"
        >
          <div class="d-flex">
            <div class="flex-shrink-0">
              <div
                class="foto-mercadoria"
                :style="{
                  backgroundImage: `url(${mercadoria.foto})`,
                }"
                v-if="mercadoria.foto"
              ></div>
              <IconeImagemInexistente v-else class="foto-mercadoria" />
            </div>
            <div class="flex-grow-1 align-self-center ms-3">
              <div>{{ mercadoria.nome }}</div>
              <div><Dinheiro :valor="mercadoria.valor" /></div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-sm-2 col-4 text-end align-self-center">
        <ContadorMercadorias
          :valor-inicial="quantidade"
          :ao-adicionar="adicionaMercadoria"
          :ao-remover="removeMercadoria"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IconeImagemInexistente from "@/components/shared/imagens/IconeImagemInexistente.vue";
import ContadorMercadorias from "./ContadorMercadorias.vue";
import Dinheiro from "@/components/shared/formatadores/Dinheiro.vue";

import { ADICIONA_ITEM, REMOVE_ITEM } from "@/store/mutacoes.js";

export default {
  name: "ItemListaMercadoria",
  components: {
    IconeImagemInexistente,
    ContadorMercadorias,
    Dinheiro,
  },
  props: {
    mercadoria: {
      type: Object,
      required: true,
    },
  },
  data() {
    const itemSalvo = this.$store.getters.buscaItem(this.mercadoria);
    return {
      quantidade: itemSalvo?.quantidade ?? 0,
    };
  },
  methods: {
    adicionaMercadoria() {
      this.$store.commit(ADICIONA_ITEM, this.mercadoria);
      this.$gtag.event("adiciona_mercadoria", { id: this.mercadoria.id });
    },
    removeMercadoria() {
      this.$store.commit(REMOVE_ITEM, this.mercadoria);
      this.$gtag.event("subtrai_mercadoria", { id: this.mercadoria.id });
    },
  },
  computed: {},
};
</script>

<style scoped>
.link-mercadoria {
  color: #212529;
  text-decoration: none;
}
.foto-mercadoria {
  width: 70px;
  height: 70px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
}
.pointer {
  cursor: pointer !important;
}
</style>
